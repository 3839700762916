
export default {
    data() {
        return {
            // elmentList:
            //     [
            //         {
            //             pcImg: require('./images/pc/banner1.jpg'),
            //             phoneImg: require('./images/mobile/banner1.jpg'),
            //             descList: [
            //                 {
            //                     descName: '防爆充电桩',
            //                     sort: 1
            //                 }, {
            //                     descName: 'Ex d IIB T4 Gb',
            //                     sort: 2
            //                 }, {
            //                     descName: 'IP66',
            //                     sort: 3
            //                 }, {
            //                     descName: '-25~+60°C',
            //                     sort: 4
            //                 }, {
            //                     descName: '5%~95%RH',
            //                     sort: 5
            //                 },
            //                 {
            //                     descName: '防爆等级',
            //                     sort: 6
            //                 }, {
            //                     descName: '防护等级',
            //                     sort: 7
            //                 }, {
            //                     descName: '工作温度',
            //                     sort: 8
            //                 }, {
            //                     descName: '环境温度',
            //                     sort: 9
            //                 }],
            //              type: 'image',
            //             sort: 1
            //         },
            //         {
            //             pcImg: require('./images/pc/banner2.jpg'),
            //             phoneImg: require('./images/mobile/banner2.jpg'),
            //             descList: [ {
            //                     descName: '适用于石油、化工、仓储、港口等防爆区域类的巡检机器人充电使用，具有输入过压保护、输入欠压保护、模块过温保护、输入过流保护、效率异常保护和异常脱离保护功能。',
            //                     sort: 1
            //                 },],
            //              type: 'image',
            //             sort: 2
            //         },
            //         {
            //             pcImg: require('./images/pc/banner3.jpg'),
            //             phoneImg: require('./images/mobile/banner3.jpg'),
            //             descList: [
            //                 {
            //                     descName: '充电保护功能',
            //                     sort: 1
            //                 }, {
            //                     descName: '具有过压、过流、过放、过温、欠压、效率异常、电涌防雷击和异常脱离保护功能。',
            //                     sort: 2
            //                 }],
            //              type: 'image',
            //             sort: 3
            //         },
            //         {
            //             pcImg: require('./images/pc/banner4.jpg'),
            //             phoneImg: require('./images/mobile/banner4.jpg'),
            //             descList: [
            //                 {
            //                     descName: '智能充电',
            //                     sort: 1
            //                 }, {
            //                     descName: '充电桩将与机器人配合，实现智能充电功能。机器人回到指定位置后，会自动进行充电，无需人工干预。充电完成，将自动断开充电。',
            //                     sort: 2
            //                 }],
            //              type: 'image',
            //             sort: 4
            //         }
            //     ],

            elmentList: [],
        }
    },
    created() {
        this.$store.dispatch("pageset/getbannerIndex", 0);
        document.documentElement.style.setProperty('--var-primary-color', '#fff');
        // 获取当前页面的配置信息
        this.$store.dispatch('pageset/getPageInfo', this.$route.query.pageId).then(() => {
            this.elmentList = this.$store.state.pageset.sourceData.elmentList;
        });
    },
    methods: {
        retention() {
            this.$router.push('/reservation');
        },
        openPdf() {
             this.$router.push('/pdfView')
        },

    }
}